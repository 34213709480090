import React from "react"

import { Card, List, Item, Frame, Image, Title, Content, Link, Pagination, Button, ButtonWrapper } from "./ListSearchResults.styled"

export const ListSearchResults = ({ items = [], limit, setLimit, size, loading }: Props): JSX.Element => (
  <Card size={"full"}>
    <List>
      {items.map((item, index) => (
        <Link to={item.link} key={index}>
          <Item>
            {item?.image && <Image image={{ ...item?.image }} alt={item.title || ""} />}
            <Frame>
              <Title>{item.title}</Title>
              <Content>{item.content}</Content>
            </Frame>
          </Item>
        </Link>
      ))}
    </List>
    {!items?.length ? (
      <Pagination>No results</Pagination>
    ) : (limit % size || items?.length < limit) && !loading ? (
      <Pagination>End of results</Pagination>
    ) : (
      <ButtonWrapper>
        <Button theme={"primary"} colour={"dark"} size={"medium"} loading={loading} onClick={() => setLimit(limit + size)}>
          Load More
        </Button>
      </ButtonWrapper>
    )}
  </Card>
)

export interface Props {
  items: Array<{
    image?: any
    title?: string
    content?: string
    link?: string
  }>
  size?: number
  limit?: number
  setLimit?: (number) => void
  loading?: boolean
}
