import React, { useMemo } from "react"

import { useData } from "../../hooks/useData"
import { useSearchContent } from "../../hooks/useSearch"

export const withSearchPage = Component =>
  React.memo(({ name = "SearchPage", children, location, ...props }) => {
    const { data } = useData()
    const { limit, setLimit, size, results, loading } = useSearchContent(location?.search?.replace("?q=", "")?.replace("%20", " "))
    const searched = location?.search?.replace("?q=", "")?.replace("%20", " ")

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          {...props}
          data={data}
          searched={searched}
          results={results}
          limit={limit}
          size={size}
          children={children}
          handleLimit={setLimit}
          loading={loading}
        />
      ),
      [children, limit, size, results, loading, searched]
    )
  })
