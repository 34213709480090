import React from "react"

import { withSearchPage } from "./withSearchPage"
import { Hero } from "../Section/Hero/Hero"
import { ListSearchResults } from "../Lists/ListSearchResults"
import { Container, HeroSmall, HeroH4 } from "./SearchPage.styled"
import { routeResolver } from "../Wrappers/Helpers"

export const SearchPage = withSearchPage(
  ({ data, searched, results, limit, size, handleLimit }: Props): JSX.Element => (
    <>
      <Hero colour={"primary"} size={"small"} backLink={"/"} extended>
        <Container width={`md`}>
          <HeroSmall>Showing results for</HeroSmall>
          <HeroH4>‘{searched}‘</HeroH4>
          <HeroSmall>{results?.length} results</HeroSmall>
        </Container>
      </Hero>
      <Container collapse={`<md`} width={`md`}>
        <ListSearchResults
          limit={limit}
          setLimit={handleLimit}
          size={size}
          items={
            results
              ?.filter(({ _type }) => _type !== "mentor")
              ?.map(item => ({
                image: item?.image || data?.logoImage.childImageSharp?.gatsbyImageData,
                title: item?.title,
                content: item?.summary || (item?.content && item?.content?.[0]?.children?.[0]?.text),
                link: routeResolver({ item }),
              })) || []
          }
        />
      </Container>
    </>
  )
)

export interface Props {
  data?: any
  searched?: string
  results?: any
  limit?: number
  size?: number
  handleLimit?: (limit: number) => void
}
