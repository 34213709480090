import React from "react"
import { graphql } from "gatsby"

import { SearchPage as Page } from "../components/Search/SearchPage"

export const query = graphql`
  query {
    page: sanityPageSearch {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 10 })
      content: _rawContent(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }): JSX.Element => <Page {...props} {...data} />
