import { Link as GatsbyLink } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import tw from "twin.macro"

import { H4, P } from "../../styled/Text"
import { StyledCard } from "../../styled/Card"
import { StyledButton } from "../../styled/Button"

export const Card = tw(StyledCard)`block p-4 mb-18 text-left rounded-3xl`
export const List = tw.ul`block mb-8`
export const Item = tw.div`flex py-5 px-4 text-left border-b border-grey-pale w-full`
export const Frame = tw.figcaption`flex-1 px-4`
export const Image = tw(GatsbyImage)`block w-16 h-16 rounded-full`
export const Title = tw(H4)`text-grey-dark lg:text-base text-base mb-0`
export const Content = tw(P)`lg:text-xs text-xs text-grey-mid`
export const Link = tw(GatsbyLink)`block`
export const Pagination = tw(P)`block lg:text-xs text-xs text-grey-dark text-center mb-5`
export const ButtonWrapper = tw.div`px-4 md:px-12 md:px-0 md:max-w-sm w-full md:mx-auto mb-6 text-center`
export const Button = tw(StyledButton)`inline-block`
